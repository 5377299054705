<template>
  <div class="">
    <el-dialog
      title=""
      custom-class="upload-dialog"
      width="540px"
      :visible.sync="fileUploadDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
    >
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="选择文件" name="upload">
          <el-upload
            class="upload-demo"
            drag
            action=""
            :auto-upload="false"
            :on-change="handleChange"
            :on-remove="removeVideo"
            :on-exceed="exceedVideo"
            :file-list="fileList"
            :multiple="multiple ? multiple : true"
            :limit="limit_num"
            :accept="accept_type ? accept_type : '.ppt,.pptx,.doc,.docx,.pdf,.zip,.xlsx,.txt,.csv,.xls'"
            style="width: 100%"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传{{ accept_type ? accept_type : '.ppt,.pptx,.doc,.docx,.pdf,.zip,.xlsx,.txt,.csv,.xls' }}文件</div>
            <!--  ，且不超过500kb        -->
          </el-upload>
        </el-tab-pane>
        <el-tab-pane label="历史记录" name="record" v-if="record_api_url != ''">
          <div class="record-img-box vertical-center">
            <el-image class="record-img" v-for="(item, index) in recordData" :key="index" @click="selectImg(item)" style="width: 100px; height: 100px" :src="item" fit="contain"></el-image>
          </div>
          <div class="page-box align-center">
            <el-pagination @current-change="handleCurrentChange" background layout="prev, pager, next" :total="1000"> </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">关 闭</el-button>
        <el-button type="primary" @click="fileSubmit" v-if="fileList.length > 0 && !upload_status">上 传</el-button>
        <el-button type="primary" @click="fileSubmit" v-if="fileList.length > 0 && upload_status"> 上传中 <i class="el-icon-loading"></i> 剩余({{ fileList.length }}) </el-button>
      </span>
    </el-dialog>

    <!-- 文件上传成功 -->
    <div>
      <div class="vertical-center">
        <!-- <div class="upload-btn-box align-center"> -->
        <el-button type="warning" class="upload-btn" style="margin-left: auto" @click="uploadDialog">上传资料</el-button>
        <!-- </div> -->

        <input type="text" :name="upload_name" v-model="upload_url" hidden />
        <input type="text" name="file_info" hidden v-model="file_info" />
      </div>

      <!-- <div v-if="file_info.length && upload_default_type === 'file'" style="margin-top: 10px;">
        <el-alert v-for="(item, index) in file_info" :key="index" :title="item.name" type="success" @close="removeImg(index)"></el-alert>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
export default {
  name: 'uploadFile',
  props: ['upload_api_url', 'folder', 'create_time', 'upload_name', 'file_name_time', 'multiple', 'limit', 'scene_type', 'default_value', 'file_info_data', 'upload_type', 'accept_type'],
  data() {
    return {
      fileUploadDialog: false,
      fileList: [], // 上传列表
      upload_url: [],
      srcList: [],
      aliyunOssToken: {},
      create_time_status: true,
      file_info: [], // 文件信息
      file_name_time_status: true, //是都文件名家时间戳
      limit_num: 1,
      upload_status: false,
      upload_scene: 'upload', // 场景显示是上传还是编辑器
      activeName: 'upload',
      record_api_url: '', //历史记录上传
      recordData: [], // 历史记录数据
      page: 1,
      recordInfo: {},
      upload_default_type: 'img',
    };
  },
  created() {
    // 是否开启时间创建
    if (this.create_time) {
      this.create_time_status = this.create_time;
    }
    if (this.file_name_time) {
      this.file_name_time_status = this.file_name_time;
    }
    if (this.limit) {
      this.limit_num = this.limit;
    }
    if (this.scene_type) {
      this.upload_scene = this.scene_type;
    }
    if (this.default_value && this.default_value.length > 0 && this.default_value[0] != '' && this.default_value[0] != undefined) {
      this.upload_url = this.default_value;
      if (this.file_info_data) {
        this.file_info = this.file_info_data;
      }
    }

    // 判断是图片还是文件 upload_type
    if (this.upload_type) {
      this.upload_default_type = this.upload_type;
    }
  },
  watch: {
    default_value() {
      if (this.default_value.length > 0 && this.default_value[0] != '') {
        this.upload_url = this.default_value;
      }
    },
  },
  mounted() {
    this.getOssToken();
  },
  methods: {
    // 上传按钮
    uploadDialog() {
      this.upload_url = [];
      this.file_info = [];
      this.fileUploadDialog = true;
    },
    // 关闭上传弹框
    closeDialog() {
      if (this.upload_status) {
        this.$message.warning('正在上传中，请勿关闭');
        return false;
      }
      this.fileUploadDialog = false;
      this.fileList = [];
      console.log(this.upload_url);
      this.upload_url = [];
      this.file_info = [];
    },
    // 选择文件
    handleChange(file, fileList) {
      var file_type = file.name.substring(file.name.lastIndexOf('.') + 1);
      this.formatLimitFun(file_type, fileList, file);
    },
    // 移除文件时
    removeVideo(file, fileList) {
      this.fileList = fileList;
    },
    // 当选择超出时
    exceedVideo(files, fileList) {
      var file_type = files[0].name.substring(files[0].name.lastIndexOf('.') + 1);
      files[0].status = 'ready';
      let _this = this;
      let current_files = files[0];
      current_files.raw = files[0];
      if (this.accept_type) {
        if (
          this.accept_type.indexOf(file_type) != -1 ||
          file_type === 'pdf' ||
          file_type === 'zip' ||
          file_type === 'doc' ||
          file_type === 'ppt' ||
          file_type === 'txt' ||
          file_type === 'xls' ||
          file_type === 'pptx' ||
          file_type === 'docx' ||
          file_type === 'csv' ||
          file_type === 'xlsx'
        ) {
          this.fileList.splice(_this.fileList.length - 1, 1, current_files);
        } else {
          this.$message.error('请按照正确格式上传!');
          return false;
        }
      } else {
        if (
          file_type === 'pdf' ||
          file_type === 'zip' ||
          file_type === 'doc' ||
          file_type === 'ppt' ||
          file_type === 'txt' ||
          file_type === 'xls' ||
          file_type === 'pptx' ||
          file_type === 'docx' ||
          file_type === 'csv' ||
          file_type === 'xlsx'
        ) {
          this.fileList.splice(_this.fileList.length - 1, 1, current_files);
        } else {
          this.$message.error('上传文件只能是.ppt,.pptx,.doc,.docx,.pdf,.zip,.xlsx,.txt,.csv,.xls格式!');
          return false;
        }
      }
    },
    // 点击确定上传
    fileSubmit() {
      var _self = this;
      if (this.fileList.length == 0) {
        return false;
      }
      this.upload_status = true;
      var filename = this.$util.randomString(40) + '.' + this.fileList[0].name.substring(this.fileList[0].name.lastIndexOf('.') + 1);
      var formData = new FormData();
      var filename_key = _self.aliyunOssToken.dir + '/' + (this.folder ? this.folder + '/' + this.getTime() + '/' + filename : this.getTime() + '/' + filename);

      //注意formData里append添加的键的大小写
      formData.append('key', filename_key); //存储在oss的文件路径
      formData.append('OSSAccessKeyId', _self.aliyunOssToken.access_id); //accessKeyId
      formData.append('policy', _self.aliyunOssToken.policy); //policy
      formData.append('Signature', _self.aliyunOssToken.signature); //签名
      formData.append('file', _self.fileList[0].raw);
      formData.append('success_action_status', 200); //成功后返回的操作码
      var url = _self.aliyunOssToken.host;
      var fileUrl =
        _self.aliyunOssToken.cnd_domain + '/' + _self.aliyunOssToken.dir + '/' + (this.folder ? this.folder + '/' + this.getTime() + '/' + filename : this.getTime() + '/' + filename);
      /*if(this.create_time_status){
        fileUrl= _self.aliyunOssToken.cnd_domain +'/'+ _self.aliyunOssToken.dir +'/' + (this.folder ? this.folder + '/' + this.getTime() + '/'+ filename : this.getTime() + '/'+ filename)
      }*/
      $.ajax({
        url: url,
        type: 'POST',
        data: formData,
        // async: false,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          if (_self.file_info.length === _self.limit_num || _self.upload_url.length === _self.limit_num) {
            _self.file_info.splice(_self.file_info.length - 1, 1, {
              size: _self.fileList[0].size,
              name: _self.fileList[0].name,
            });
            _self.upload_url.splice(_self.upload_url.length - 1, 1, fileUrl);
          } else {
            _self.file_info.push({
              size: _self.fileList[0].size,
              name: _self.fileList[0].name,
            });
            _self.upload_url.push(fileUrl);
          }
          if (_self.upload_scene == 'editor') {
            _self.fileUploadDialog = false;
          }

          if (_self.fileList.length > 0) {
            _self.fileList.splice(0, 1);
            _self.upload_status = false;
            _self.fileSubmit();
          }
          if (_self.fileList.length == 0) {
            _self.fileUploadDialog = false;

            _self.fileList = [];
            _self.$message.success('上传成功');
            _self.upload_status = false;

            // 回调地址
            let data = {
              url: _self.upload_url,
              info: _self.file_info,
            };
            //console.log(data, '==============');
            _self.$emit('successUrl', data);
            _self.$emit('successFile', _self.file_info);

            if (_self.upload_scene == 'editor') {
              setTimeout(() => {
                _self.upload_url = [];
                _self.file_info = [];
              }, 1000);
            }
          }
        },
        error: function (data) {
          _self.$message.error('上传失败');
          _self.upload_status = false;
        },
      });
    },
    // 图片删除
    removeImg(index) {
      this.upload_url.splice(index, 1);
      this.file_info.splice(index, 1);
      let data = {
        url: this.upload_url,
        info: this.file_info,
      };
      this.$emit('successUrl', data);
      this.$emit('successFile', this.file_info);
    },
    // 历史记录选择图片
    selectImg(item) {
      if (this.multiple) {
        if (this.upload_url.length === this.limit) {
          this.upload_url.splice(this.upload_url.length - 1, 1, item);
        } else {
          this.upload_url.push(item);
        }
      } else {
        this.upload_url = [];
        this.upload_url.push(item);
      }
      this.fileUploadDialog = false;
      this.$emit('successUrl', this.upload_url);
    },
    // 获取历史记录数据
    getHistory() {
      if (this.record_api_url != '') {
        let url = this.record_api_url + '?page=' + this.page + '&pageSize=18';
        axios.get(url).then((res) => {
          if (res.data.success) {
            this.recordInfo = res.data.data;
            this.recordData = res.data.data;
          }
        });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getHistory();
    },
    // 获取上传通行证
    getOssToken() {
      var _self = this;
      axios.get(_self.upload_api_url ? _self.upload_api_url : 'oss/sign').then((res) => {
        var data = res.data;
        if (data.success) {
          _self.aliyunOssToken = data.data;
        } else {
          _self.$message.warning(data.msg);
        }
      });
    },
    // 获取当前年月
    getTime() {
      var date = new Date();
      var yarn = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? '0' + month : month;
      return yarn.toString() + '' + month.toString();
    },
    // tab选项
    handleClick(tab, event) {},
    // 格式限制方法
    formatLimitFun(file_type, fileList, file) {
      if (this.accept_type) {
        if (
          this.accept_type.indexOf(file_type) != -1 ||
          file_type === 'pdf' ||
          file_type === 'zip' ||
          file_type === 'doc' ||
          file_type === 'ppt' ||
          file_type === 'txt' ||
          file_type === 'xls' ||
          file_type === 'pptx' ||
          file_type === 'docx' ||
          file_type === 'csv' ||
          file_type === 'xlsx'
        ) {
          this.fileList = fileList;
        } else {
          this.$message.error('请按照正确格式上传!');
          this.fileList = [];
          return false;
        }
      } else {
        if (
          file_type === 'pdf' ||
          file_type === 'zip' ||
          file_type === 'doc' ||
          file_type === 'ppt' ||
          file_type === 'txt' ||
          file_type === 'xls' ||
          file_type === 'pptx' ||
          file_type === 'docx' ||
          file_type === 'csv' ||
          file_type === 'xlsx'
        ) {
          let imgSize = Number(file.size / 1024 / 1024);
          if (imgSize > 2048) {
            this.$message.error('上传文件不能超过2GB!');
            this.fileList = [];
          } else {
            this.fileList = fileList;
          }
        } else {
          this.$message.error('上传文件只能是.ppt,.pptx,.doc,.docx,.pdf,.zip,.xlsx,.txt,.csv.xls格式!');
          this.fileList = [];
          return false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.upload-btn-box {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border: 1px dashed #d0d6de;
  border-radius: 6px;
  cursor: pointer;
  .hint-icon {
    font-size: 36px;
    color: #93999f;
    text-align: center;
  }
  .hint-text {
    margin-top: 6px;
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    color: #93999f;
    text-align: center;
  }
}
.upload-image-box {
  width: 100px;
  height: 100px;
  position: relative;
  margin-right: 12px;
  .bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    display: none;
  }
  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 2;
    color: #ffffff;
    display: none;
  }
}
.upload-image-box:hover {
  .bg {
    display: block;
  }
  .close-icon {
    display: block;
  }
}
.url-value {
  color: #1c1f21;
  border: none;
  background: none;
  margin-top: 10px;
  width: 100%;
}
.record-img-box {
  flex-wrap: wrap;
  justify-content: space-between;
}
.record-img {
  cursor: pointer;
  border: 1px solid #e4e7ed;
  margin-bottom: 12px;
}
.page-box {
  margin-top: 16px;
}

.el-alert {
  line-height: initial;
  box-sizing: border-box;
  padding: 2px 10px;
  /deep/ .el-alert__content {
    width: 100%;
    display: flex;
    padding: 2px 10px;
  }
  /deep/ .el-alert__title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
    flex: 1;
    padding: 2px 50px 2px 0;
  }
  /deep/ .el-alert__closebtn {
    top: 6px;
    right: 10px;
  }
}
.upload-demo {
  width: 100%;
  /deep/ .el-upload {
    width: 100%;
  }
  /deep/ .el-upload-dragger {
    width: 100%;
  }
}
.upload-btn {
  background: #ffa509;
}
.upload-btn:hover,
.upload-btn:focus {
  background: #ebb563;
  border-color: #ebb563;
}
</style>

<style>
.upload-dialog .el-dialog__header {
  display: none;
}
</style>
